<template>

  <div class="position">
          <div class="position-model">
              <div class="position-model-img">
                 <img src="@/assets/img/z404.png" alt="">
              </div>
              <div class="q404">抱歉，您访问的页面不存在~</div>
          <div @click="close" class="close">返回</div>
          </div>
  </div>

</template>

<script>
export default {
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {},

  data() {
    //数据

    return {};
  },
  methods: {
    //方法.
    close(){
      this.$router.back(-1)
    }
  },
};
</script>

<style scoped>
.close{
width: 84px;
height: 32px;
opacity: 1;
background: #2377e3;
border-radius: 4px;
color: white;
cursor: pointer;
margin: auto;
line-height: 32px;
margin-top:25px;
}
.close :hover{
  
   transition: 0.25s;
   
}
.position{
  width: 100%;
 
  height: 100%;
  overflow: hidden;
}
.position-model{
  width: 600px;
height: 300px;
text-align: center;
 
  position:absolute;
  top: 30%;
  left: 50%;
   transform: translate(-50%, -50%);
  
}
.position-model-img{
  width: 369px;
  height: 249px;
  margin: auto;
 
}
img{
  width: 100%;
  height: 100%;


}
.q404{
  font-size: 16px;
 

    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin-top:23px ;
}
</style>
